import {useObserver} from "mobx-react";
import React, {FC, useState} from "react";
import {Button, Col, Input, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css'
import {useRootStore} from "src/utilities";
import {StandardReactTable, TypedColumn} from "src/components/StandardReactTable";
import {GameLevelDto, GameModeDto} from "src/api";
import {InfoBox} from "src/components/InfoBox";
import {AdminModesAndLevelsTab} from "src/stores/admin/AdminGameModesStore";
import {ValidatedInput} from "src/components/ValidatedInput/ValidatedInput";
import {NumericField} from "src/components/NumericInput";

const GameModesEditor : FC<{item : GameModeDto}> = (props) => {
    const{
        rootStore: {adminGameModesStore : store}
    } = useRootStore();
    const item: GameModeDto = props.item;
    return useObserver(() => (
        <div className={"table-sub-component"}>
            <Table bordered={false} borderless={true}>
                <tr>
                    <td width={"25%"}>Description:</td>
                    <td><input className={"form-control"} type={"text"} value={item.description} onChange={e => item.description = e.target.value}/></td>
                </tr>
                <tr>
                    <td>Allow bots:</td>
                    <td>
                        <input type={"checkbox"} checked={item.allowBots} onChange={e => item.allowBots = e.target.checked}/>
                    </td>
                </tr>
            </Table>
            <div className={"save-button"}>
                <Button className={"buttonDarkBlue"} onClick={async () => {
                    await store.editMode(item);
                }}>Save</Button>
            </div>
        </div>
    ));
    
}

const AdminGameModesList: FC = () => {
    const {
        rootStore: { adminGameModesStore: store },
    } = useRootStore();
    
    const modesColumns: TypedColumn<GameModeDto>[] = [
            {
                id : "id",
                accessor : x => x.id,
                Header : "ID",
                maxWidth : 50,
            },
            {
                id : "name",
                    accessor : x => x.name,
                Header : "Name"
            },
            {
                id : "desc",
                    accessor : x => x.description,
                Header : "Description"
            },
            {
                id : "bots",
                accessor : x => x.allowBots ? "Yes" : "No",
                Header : "Allow bots"
            },
        ];


    return useObserver(() => (
        <div>
            <p>
                <StandardReactTable<GameModeDto> columns={modesColumns} store={store} subComponent={row => <GameModesEditor item={row}/>} />
            </p>
        </div>
    ));
};

export type GameLevelsEditorProps = {
    item: GameLevelDto;
    onChange : (d: GameLevelDto) => void;
}

const GameLevelsEditor : FC<GameLevelsEditorProps> = (props) => {
    const [name,setName] = useState<string>(props.item.displayName);
    const [maxPlayers, setPlayers] = useState<number | undefined>(props.item.maxPlayers);
    return (
        <div className={"table-sub-component"}>
            <Table bordered={false} borderless={true}>
                <tr>
                    <td width={"25%"}>Display name:</td>
                    <td>
                        <Input className={"form-control"} type={"text"} value={name} onChange={e => setName(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td>Max players:</td>
                    <td>
                        <NumericField value={maxPlayers} onChange={setPlayers}/>
                    </td>
                </tr>
            </Table>
            <div className={"save-button"}>
                    <Button disabled={maxPlayers == undefined} className={"buttonDarkBlue"} onClick={() => {
                        props.onChange({...props.item, displayName : name, maxPlayers : maxPlayers!})
                    }}>Save</Button>
            </div>
        </div>
    );
}

const AdminGameLevelsList:FC = () => {
    const {
        rootStore: { adminGameLevelsStore: store },
    } = useRootStore();
    const levelColumns: TypedColumn<GameLevelDto>[] = [
        {
            id : "id",
            accessor : x => x.id,
            Header : "ID"
        },
        {
            id : "name",
            accessor : x => x.name,
            Header : "Name"
        },
        {
            id : "display_name",
            accessor : x => x.displayName,
            Header : "Description"
        },
        {
            id : "max_players",
            accessor : x => x.maxPlayers,
            Header: "Max Players"
        },
        {
            id : "game_mode",
            accessor : x => x.gameMode.name,
            Header : "Game Mode"
        },
    ];
    return useObserver(() => (
        <div>
            <StandardReactTable columns={levelColumns} store={store} subComponent={row => <GameLevelsEditor onChange={async (x:GameLevelDto) => {
                await store.editLevel(x)
            }} item={row}/>} />
        </div>
    ));
}

export const AdminGameModesAndLevelsPage:FC = () => {
    const {
        rootStore: { adminModesAndLevelsPageStore: store },
    } = useRootStore();
    
    const toggle = (tab: AdminModesAndLevelsTab) => {
        store.activeTab = tab;
    }
    return useObserver(() => (
        <div>
            <InfoBox.Header>Game modes and levels</InfoBox.Header>
            <InfoBox.Content>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={store.activeTab == AdminModesAndLevelsTab.Levels}
                            onClick={() => toggle(AdminModesAndLevelsTab.Levels)}>Levels</NavLink>
                        <NavLink active={store.activeTab == AdminModesAndLevelsTab.Modes}
                            onClick={() => toggle(AdminModesAndLevelsTab.Modes)}>Modes</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={store.activeTab}>
                    <TabPane tabId={AdminModesAndLevelsTab.Levels}>
                        <AdminGameLevelsList/>
                    </TabPane>
                    <TabPane tabId={AdminModesAndLevelsTab.Modes}>
                        <AdminGameModesList/>
                    </TabPane>
                </TabContent>
            </InfoBox.Content>
        </div>
    ))
};

export default AdminGameModesAndLevelsPage;