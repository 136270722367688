import {RootStore} from "src/stores/RootStore";
import {ReactTableStore2} from "src/stores/interfaces/ReactTableStore";
import {observable} from "mobx";
import {GameCharacterDto} from "src/api";

export class AdminGameCharactersStore extends ReactTableStore2<GameCharacterDto, { }>
{
    @observable rootStore: RootStore; 
    constructor(readonly root: RootStore) 
    {
        super()
        this.rootStore = root;
    }

    getFilters(): {} {
        return {};
    }
    
    async editCharacter(dto: GameCharacterDto){
        await this.rootStore.userRpc.gameContentEdit.editCharacter(dto);
        this.forceRefresh();
    }
    
    async getItemsAsync(search: string, filters: {}, skip: number, take: number): Promise<{ items: GameCharacterDto[]; itemsTotal: number }> {
        var itemsFromRpc = await this.rootStore.userRpc.gameContentView.getCharacters();
        return {
            items : itemsFromRpc,
            itemsTotal : itemsFromRpc.length
        };
    }
}

