import { RootStore } from "src/stores/RootStore";
import { AutoCompleteSelectStore } from "src/stores/interfaces/AutoCompleteSelectStore";
import { action, observable } from "mobx";
import {UserSessionAndStatsDto, UserInfoDto, UserStatsTable} from "src/api";

export class UserMainStore implements AutoCompleteSelectStore<{ title: string }> {
    constructor(private readonly root: RootStore) {}

    @observable items: { title: string }[] = [];
    @observable labelField: string = "title";
    @observable valueField: string = "title";
    
    @observable stats?: UserStatsTable;
    @observable info?:UserInfoDto;
    @observable summary?:UserSessionAndStatsDto;

    @action async suggest(query: string): Promise<void> {
        this.items = [{ title: "Hello" }, { title: "World" }].filter((item) => item.title.includes(query));
    }

    @action async load(): Promise<void> {
        this.stats = await this.root.userRpc.userInfo.getUserStats();
        this.info = await this.root.userRpc.userInfo.getUserSettings();
        this.summary = await this.root.userRpc.userInfo.getSummary();
    }
}
