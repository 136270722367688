import { RootStore } from "src/stores/RootStore";
import { action, computed, observable, runInAction } from "mobx";
import {UserProfileDto, UserInfoDto} from "src/api";
import { validate, IsNotEmpty } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";
import { UserRouteNames } from "src/pages/user/UserRoutes";

type UserProfileStoreErrors = {
    firstName?: string[];
    lastName?: string[];
};

export class UserProfileStore {
    @observable error?: string;
    @observable errors: UserProfileStoreErrors;
    @observable profile?: UserProfileDto;
    @observable userInfo?: UserInfoDto;

    @IsNotEmpty({ message: "Обязательно для заполнения" }) @observable firstName;
    @IsNotEmpty({ message: "Обязательно для заполнения" }) @observable lastName;
    @observable email;

    constructor(private readonly root: RootStore) {
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.errors = {};
    }

    @computed get loading() {
        return false;
    }

    @computed get initialized() {
        return true;
    }

    @action async loadAll() {
        this.profile = await this.root.userRpc.userProfile.getProfile();
        this.userInfo = await this.root.userRpc.userInfo.getUserSettings();
        const { name, email } = this.profile;
        const [firstName = "", lastName = ""] = name.split(" ");
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
    }

    @action
    async updateProfile() {
        const { firstName, lastName, email } = this;
        const id = this.profile?.id;
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors(errors);
            return;
        }

        const res = await this.root.userRpc.userProfile.updateProfile({
            id,
            email,
            name: `${firstName} ${lastName}`,
        } as UserProfileDto);
        if (!res.success) {
            runInAction(() => (this.error = res.error.description));
            return;
        }

        await this.loadAll();
        await this.root.routerStore.goTo(UserRouteNames.mainPage);
    }
}
