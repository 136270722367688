import React, { FC } from "react";
import styles from "./footer.module.css";
import { Logo } from "src/components/NewComponents/UI/Logo/Logo";

type FooterProps = {
    backgroundColor?: string;
};

export const Footer: FC<FooterProps> = ({ backgroundColor }) => (
    <div className={styles.footer__back} style={{ background: backgroundColor }}>
        <div className={styles.footer__maxWidth + " d-flex"}>
            <div className={styles.footer__col}>
                <a href={"/"} target={"__blank"}>
                    <Logo className={""} />
                </a>
            </div>
            <div className={styles.footer__col}>
                <div className={styles.footer__colItem} />
            </div>
            <div className={styles.footer__col + " " + styles.footer__colCopy}>
                © 2022 BitSoftware ASP.NET Core Server-Side
            </div>
        </div>
    </div>
);
