import React from "react";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { Route, RouterState } from "mobx-state-router";
import { AdminMainPage } from "src/pages/admin/AdminLazyRoutes";
import { AnonRouteNames } from "src/pages/anon/AnonRoutes";
import AdminGameCharactersPage from "src/pages/admin/Game/AdminGameCharactersPage";
import AdminWeaponStatsPage from "src/pages/admin/Game/AdminWeaponStatsPage";
import AdminGameModesAndLevelsPage from "src/pages/admin/Game/AdminGameModesAndLevelsPage";
import {AdminGameSettingsPage} from "src/pages/admin/Settings/GameSettingsPage";
import {AdminLevelStatsPage} from "src/pages/admin/Game/AdminLevelStatsPage";
import AdminLootBoxesList from "src/pages/admin/Game/AdminLootBoxesPage";

const AdminAuthorizedOnlyHook: RouteTransitionHook = (root) => {
    if (!root.userRpc.isAuthorized) throw new RouterState(AnonRouteNames.adminLogin);
};

export enum AdminRouteNames {
    mainPage = "admin-main-page",
    gameCharacters = "admin-game-chars",
    modesAndLevels = "admin-modes-and-levels",
    weaponStats = "admin-weapon-stats",
    gameSettings = "admin-game-settings",
    levelStats = "admin-level-stats",
    sessionStats = "admin-session-stats",
    lootBoxes = "admin-lootbox"
}

export const AdminViewMap = {
    [AdminRouteNames.mainPage]: <AdminMainPage />,
    [AdminRouteNames.gameCharacters]: <AdminGameCharactersPage/>,
    [AdminRouteNames.weaponStats]: <AdminWeaponStatsPage/>,
    [AdminRouteNames.modesAndLevels] : <AdminGameModesAndLevelsPage/>,
    [AdminRouteNames.gameSettings] : <AdminGameSettingsPage/>,
    [AdminRouteNames.levelStats] : <AdminLevelStatsPage/>,
    [AdminRouteNames.sessionStats] : <AdminMainPage/>,
    [AdminRouteNames.lootBoxes]: <AdminLootBoxesList/>
};

export const AdminRoutes: Route[] = convertRoutes([
    {
        pattern: "/admin",
        name: AdminRouteNames.mainPage,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminMainStore.load({}),
    },
    {
        pattern: "/admin/game/characters",
        name: AdminRouteNames.gameCharacters,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminMainStore.load({}),
    },
    {
        pattern: "/admin/stats/weapon",
        name: AdminRouteNames.weaponStats,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminMainStore.load({})
    },
    {
        pattern: "/admin/game/modes-and-levels",
        name: AdminRouteNames.modesAndLevels,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminMainStore.load({})
    },
    {
        pattern: "/admin/game/settings",
        name: AdminRouteNames.gameSettings,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminGameSettingsStore.reload()
    },
    {
        pattern: "/admin/stats/level",
        name: AdminRouteNames.levelStats,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminLevelStatsStore.load()
    },
    {
        pattern: "/admin/sessions/:id/:user",
        name: AdminRouteNames.sessionStats,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminSessionInfoStore.load(parseInt(to.params['id']), parseInt(to.params['user']))
    },
    {
        pattern: "/admin/lootboxes",
        name: AdminRouteNames.lootBoxes,
        hooks: [AdminAuthorizedOnlyHook],
        onEnter: (root, to) => root.adminLootBoxesStore.ensureLoaded()
    }
]);
