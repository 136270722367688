import {ReactTableStore2} from "../interfaces/ReactTableStore";
import {GameLevelDto, GameModeDto} from "src/api";
import {observable} from "mobx";
import {RootStore} from "../RootStore";

export class AdminGameModesStore extends ReactTableStore2<GameModeDto, {}> {
    @observable rootStore: RootStore;

    constructor(readonly root: RootStore) {
        super();
        this.rootStore = root;
    }
    
    async editMode(dto: GameModeDto){
        await this.root.userRpc.gameContentEdit.editGameMode(dto);
        this.forceRefresh();
    }

    getFilters(): {} {
        return {};
    }

    async getItemsAsync(search: string, filters: {}, skip: number, take: number): Promise<{ items: GameModeDto[]; itemsTotal: number }> {
        var itemsFromRpc = await this.rootStore.userRpc.gameContentView.getGameModes();
        return {
            items: itemsFromRpc,
            itemsTotal: itemsFromRpc.length
        };
    }
}

export class AdminGameLevelsStore extends ReactTableStore2<GameLevelDto, {}> {
    @observable rootStore: RootStore;

    constructor(readonly root: RootStore) {
        super();
        this.rootStore = root;
    }

    getFilters(): {} {
        return {};
    }
    
    async editLevel(dto: GameLevelDto){
        await this.rootStore.userRpc.gameContentEdit.editLevel(dto);
        this.forceRefresh();
    }

    async getItemsAsync(search: string, filters: {}, skip: number, take: number): Promise<{ items: GameLevelDto[]; itemsTotal: number }> {
        var itemsFromRpc = await this.rootStore.userRpc.gameContentView.getLevels();
        return {
            items: itemsFromRpc,
            itemsTotal: itemsFromRpc.length
        };
    }
}

export enum AdminModesAndLevelsTab { Modes = 0, Levels = 1 }

export class AdminModesAndLevelsStore
{
    @observable activeTab: AdminModesAndLevelsTab;
    @observable modesStore : AdminGameModesStore;
    @observable levelsStore : AdminGameLevelsStore;
    @observable rootStore: RootStore;
    
    constructor(root: RootStore) 
    {
        this.rootStore = root;
        this.activeTab = AdminModesAndLevelsTab.Levels;
        this.modesStore = root.adminGameModesStore;
        this.levelsStore = root.adminGameLevelsStore;
    }
}

