import React, { FC, useState } from "react";
import styles from "./header.module.css";
import { Logo } from "src/components/NewComponents/UI/Logo/Logo";
import { RouterLink } from "mobx-state-router";
import { RouterLinkProps } from "mobx-state-router/dist/types/components/router-link";
import { useObserver } from "mobx-react";
import { BadgeList, BadgeTypes } from "src/components/NewComponents/UI/BadgeList/BadgeList";
import { Footer } from "src/components/NewComponents/Footer/Footer";
import { StringMap } from "mobx-state-router/dist/types/router-store";

export type HeaderTitleLink = {
    title: string;
    route: string;
    args?: StringMap;
};

export enum HeaderTitleType {
    text = "plain-text",
    linkGroup = "link-group",
    stats = "stats",
}

export type HeaderEntry = {
    type: HeaderTitleType;
    title?: string;
    linkGroup?: HeaderTitleLink[];
    subtitle?: string;
    subtitleMobile?: string;
    description?: string;
    pills?: BadgeTypes[];
    documentLink?: string;
    documentTitle?: string;
    brandingImageUrl?: string;
    brandingColor?: string;
    showFooter?: boolean;
};

export type Header = {
    [id: string]: HeaderEntry;
};

export const RouterNavLink: FC<RouterLinkProps> = (props) => {
    const { className, activeClassName, ...pass } = props;
    return (
        <RouterLink
            className={styles.header__headLink + " " + className}
            activeClassName={styles.header__headLink_active}
            {...pass}
        />
    );
};

export const HeaderLink = ({ name, text }: { name: string; text: string }) => (
    <RouterNavLink routeName={name}>{text}</RouterNavLink>
);

type HeaderHeadProps = {
    headerNav?: React.ReactNode;
    userWidget?: React.ReactNode;
    logo?: React.ReactNode;
    entry: HeaderEntry;
    logout?: () => void;
};

const HeaderHead: FC<HeaderHeadProps> = ({ logo, headerNav, userWidget, entry, logout }) => {
    const [headerOpen, setHeaderOpen] = useState(false);
    return useObserver(() => {
        const headerMobile = `${styles.header__mobile} ${headerOpen ? styles.header__mobile_open : ""}`;
        const headerJustify = headerNav && userWidget ? "justify-content-between" : "justify-content-center";
        return (
            <div
                className={
                    styles.header__head + " d-flex justify-content-lg-between align-items-center " + headerJustify
                }
            >
                {!logo ? (
                    <a className={styles.header__headLogo} href={"/"} target={"__blank"}>
                        <Logo />
                    </a>
                ) : (
                    logo
                )}
                {headerNav && (
                    <div className={headerMobile}>
                        <button className={styles.header__burger} onClick={() => setHeaderOpen(!headerOpen)}>
                            <svg
                                width="30"
                                height="20"
                                viewBox="0 0 30 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect className={styles.header__burger_1} width="30" height="2" fill="white" />
                                <rect className={styles.header__burger_2} y="9" width="30" height="2" fill="white" />
                                <rect className={styles.header__burger_3} y="18" width="30" height="2" fill="white" />
                            </svg>
                        </button>
                        <div
                            className={`${styles.header__headMenu} ${logout ? "" : styles.header__headMenu_auth}`}
                            style={{ background: entry.brandingColor }}
                            onClick={() => setHeaderOpen(false)}
                        >
                            {headerNav}
                            {logout && (
                                <button
                                    className={
                                        styles.header__headLink + " d-flex p-0 " + styles.header__headLink_button
                                    }
                                    onClick={() => logout()}
                                >
                                    ВЫЙТИ ИЗ СИСТЕМЫ
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {userWidget && (
                    <div className={`${styles.header__headUser} ${logout ? "" : styles.headUser_auth}`}>
                        {userWidget}
                    </div>
                )}
                {/*{!logout && <img className={styles.headUser_auth_img} src={logo} alt="" />}*/}
            </div>
        );
    });
};

type HeaderStatusProps = {
    entry: HeaderEntry;
};

const HeaderStatus: FC<HeaderStatusProps> = ({ entry }) => {
    const subtitleVar = window.innerWidth > 768 ? entry.subtitle : entry.subtitleMobile ?? entry.subtitle;
    return (
        <div className={styles.header__status + " d-flex flex-wrap align-items-center"}>
            {entry.pills && (
                <span className={styles.header__statusPill}>
                    <BadgeList statuses={entry.pills} />
                </span>
            )}
            <div className={styles.header__statusText + " mt-2"}>
                {subtitleVar}{" "}
                {!!entry.documentLink && !!entry.documentTitle && (
                    <a className={styles.header__documentLink} href={entry.documentLink} target={"__blank"}>
                        {entry.documentTitle}
                    </a>
                )}
            </div>
        </div>
    );
};

type HeaderTitleProps = {
    entry: HeaderEntry;
    statsWidget?: React.ReactChild;
};

const HeaderTitle: FC<HeaderTitleProps> = ({ entry, statsWidget }) => {
    return (
        <div className={styles.header__back} style={{ background: entry.brandingColor }}>
            <div className={styles.header__branding}>
                {!!entry.brandingImageUrl && (
                    <div className={styles.header__contestBranding}>
                        <img src={entry.brandingImageUrl} alt={entry.description} />
                    </div>
                )}
                <div className={"w-100"}>
                    {entry.type === HeaderTitleType.stats && (
                        <div className={styles.header__statusBar}>{statsWidget}</div>
                    )}
                    <div className={styles.header__title + " d-flex align-items-center"}>
                        {entry.type === HeaderTitleType.text && (
                            <h1 className={styles.header__h1 + " mr-2"}>{entry.title}</h1>
                        )}
                        {(entry.type === HeaderTitleType.linkGroup || entry.type === HeaderTitleType.stats) && (
                            <div className="d-flex flex-wrap">
                                {entry.linkGroup!.map((link, index) => (
                                    <RouterNavLink
                                        key={index}
                                        className={styles.header__h1_link + " mr-3"}
                                        routeName={link.route}
                                        params={link.args}
                                    >
                                        {link.title}
                                    </RouterNavLink>
                                ))}
                            </div>
                        )}
                    </div>
                    {entry.description && <div className={styles.header__description}>{entry.description}</div>}
                    <HeaderStatus entry={entry} />
                </div>
            </div>
        </div>
    );
};

export type HeaderProps = {
    headerNav?: React.ReactNode;
    userWidget?: React.ReactNode;
    entry: HeaderEntry;
    logout?: () => void;
    statsWidget?: React.ReactChild;
    logo?: React.ReactNode;
};

export const Header: FC<HeaderProps> = ({ logo, headerNav, userWidget, entry, logout, statsWidget }) => {
    return useObserver(() => (
        <div className={styles.header__back} style={{ background: entry.brandingColor }}>
            <div className={styles.header__maxWidth}>
                <div className={"pb-0"}>
                    <HeaderHead
                        headerNav={headerNav}
                        userWidget={userWidget}
                        entry={entry}
                        logout={logout}
                        logo={logo}
                    />
                </div>
                <HeaderTitle entry={entry} statsWidget={statsWidget} />
            </div>
        </div>
    ));
};

export const Wrapper: FC<HeaderProps> = ({ logo, headerNav, userWidget, entry, logout, children, statsWidget }) => {
    return useObserver(() => (
        <React.Fragment>
            <Header
                entry={entry}
                headerNav={headerNav}
                userWidget={userWidget}
                logout={logout}
                statsWidget={statsWidget}
                logo={logo}
            />
            {children}
            {entry.showFooter && <Footer backgroundColor={entry.brandingColor} />}
        </React.Fragment>
    ));
};
