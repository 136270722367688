import { RootStore } from "src/stores/RootStore";
import { action, observable } from "mobx";
import { validate, IsEmail, MinLength } from "@keroosha/class-validator";
import { MatchProperty, reduceValidationErrorsToErrors } from "src/utilities";
import { AnonRouteNames } from "src/pages/anon/AnonRoutes";

type AnonUserRegisterStoreErrors = {
    email?: string[];
    mobile?: string[];
    password?: string[];
    repeatPassword?: string[];
    serverResponseError?: string;
};

export class AnonUserRegisterStore {
    @observable isLoading: boolean = false;
    @observable errors: AnonUserRegisterStoreErrors;
    @observable formError;

    @observable agreement: boolean;
    @IsEmail({}, { message: "Неверный адрес электронной почты" }) @observable email;
    @MinLength(8, { message: "Слабый пароль. Используйте как минимум 8 символов." }) @observable password;
    @MatchProperty("password", { message: "Пароли не совпадают" }) @observable repeatPassword;

    @action resetFields() {
        this.password = "";
        this.repeatPassword = "";
        this.email = "";
        this.formError = "";
        this.errors = {};
        this.agreement = false;
    }

    constructor(private readonly root: RootStore) {
        this.password = "";
        this.repeatPassword = "";
        this.email = "";
        this.formError = "";
        this.errors = {};
        this.agreement = false;
    }

    @action async alreadyRegistered() {
        this.root.anonUserLoginStore.resetFields();
        this.root.anonUserLoginStore.login = this.email;
        const { routerStore } = this.root;
        await routerStore.goTo(AnonRouteNames.userLogin);
    }

    @action async register() {
        this.isLoading = true;
        this.errors = {};

        const { routerStore } = this.root;
        const errors = await validate(this);
        if (errors.length || !this.agreement) {
            this.errors = reduceValidationErrorsToErrors(errors);
            this.isLoading = false;
            return;
        }

        try {
            const response = await this.root.userRpc.userLogin.register(this.email, this.password);
            if (response.success) {
                this.root.anonUserLoginStore.notifyConfirmationEmailSent();
                this.root.anonUserLoginStore.login = this.email;
                this.resetFields();
                await routerStore.goTo(AnonRouteNames.userLogin);
            } else {
                this.errors.serverResponseError = response.error.description;
            }
        } catch (e) {
            this.errors.serverResponseError = "Что-то пошло не так, свяжитесь с администратором.";
            console.warn(e);
        } finally {
            this.isLoading = false;
        }
    }
}
