import { useObserver } from "mobx-react";
import React, { FC } from "react";
import { useRootStore } from "src/utilities";
import {ContentEditorListPage} from "src/pages/admin/ContentEditorPage";
import {CheckBox} from "src/components/CheckBox/CheckBox";
import {Button, Col, Row, Table} from "reactstrap";
import {StandardReactTable} from "src/components/StandardReactTable";
import {GameCharacterDto} from "src/api";
import { InfoBox } from "src/components/InfoBox";
import '../../../styles/page.module.css'

const AdminGameCharacterSubComponent: FC<{item : GameCharacterDto}> = (props) => {
    const {
        rootStore: { adminGameCharactersStore: store },
    } = useRootStore();
    const item: GameCharacterDto = props.item;
    return useObserver(() => (
       <div className={"table-sub-component"}>
           <Table bordered={false} borderless={true}>
               <Row>
                   <Col>Name:</Col>
                   <Col><input className={"form-control"} type={"text"} value={item.name} onChange={e => item.name = e.target.value}/></Col>
               </Row>
               <Row>
                   <Col>Description:</Col>
                   <Col><input className={"form-control"} type={"text"} value={item.description} onChange={e => item.description = e.target.value}/></Col>
               </Row>
           </Table>
           <div className={"save-button"}>
                <Button className={"buttonDarkBlue"} onClick={async () => await store.editCharacter(item)}>Save</Button>
           </div>
       </div> 
    ));
}

export const AdminGameCharactersList: FC = () => {
    const {
        rootStore: { adminGameCharactersStore: store },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <InfoBox.Header>
                Characters
            </InfoBox.Header>
            <InfoBox.Content>
                <StandardReactTable<GameCharacterDto> columns={[
                    {
                        id : "id",
                        accessor : x => x.id,
                        Header : "ID"
                    },
                    {
                        id : "name",
                        accessor : x => x.name,
                        Header : "Name"
                    },
                    {
                        id : "desc",
                        accessor : x => x.description,
                        Header : "Description"
                    },
                    {
                        id : "system_name",
                        accessor : x => x.systemName,
                        Header : "System Name"
                    },
                    {
                        id : "default",
                        accessor : x => x.isDefault ? "Yes" : "No",
                        Header : "Is Default",
                    }
                ]} store={store} subComponent={row => <AdminGameCharacterSubComponent item={row}/> } />
            </InfoBox.Content>
        </div>
    ));
};

export default AdminGameCharactersList;