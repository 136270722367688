import React, {FC} from "react";
import {useRootStore} from "src/utilities";
import {useObserver} from "mobx-react";
import {InfoBox} from "src/components/InfoBox";
import {StandardReactTable} from "src/components/StandardReactTable";
import {GameCharacterDto, LootBoxDto} from "src/api";
import {Button, Collapse} from "reactstrap";

export const AdminLootBoxesList: FC = () => {
    const {
        rootStore: { adminLootBoxesStore: store },
    } = useRootStore();
    return useObserver(() => (
        <div>
            <InfoBox.Header>
                LootBoxes
            </InfoBox.Header>
            <InfoBox.Content>
                <div>
                    Count of rolls: <input value={store.countToRoll} onChange={v => store.countToRoll = parseInt(v.target.value)}/>
                </div>
                <Collapse isOpen={store.resultsVisible}>
                    <h3>Roll results</h3>
                </Collapse>
                <StandardReactTable<LootBoxDto> columns={[
                    {
                        id : "id",
                        accessor : x => x.id,
                        Header : "ID"
                    },
                    {
                        id : "name",
                        accessor : x => x.name,
                        Header : "Name"
                    },
                    {
                        id : "roll",
                        Header : "Test LootBox",
                        accessor : x => <Button onClick={v => store.roll(x.id)}>Roll</Button>
                    },
                ]} store={store}/>
            </InfoBox.Content>
        </div>
    ));
};

export default AdminLootBoxesList;