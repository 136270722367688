import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { HeaderLink } from "src/components/NewComponents/Header/Header";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { AnonRouteNames, AnonViewMap } from "src/pages/anon/AnonRoutes";

export const AnonRoutes = () => <HeaderLink name={AnonRouteNames.notFound} text={"СТРАНИЦА 404"} />;

export const AnonWidget = () => <HeaderLink name={AnonRouteNames.userLogin} text={"ВОЙТИ"} />;

export const AnonShell = () => {
    const {
        rootStore: { routerStore },
    } = useRootStore();
    return useObserver(() => (
        <SuspensePlaceholder>
            <RouterView routerStore={routerStore} viewMap={AnonViewMap} />
        </SuspensePlaceholder>
    ));
};
