import { RootStore } from "src/stores/RootStore";
import { action, observable, runInAction } from "mobx";
import { validate, IsNotEmpty } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";
import { AuthErrorFields, AuthStore } from "src/stores/interfaces/AuthStore";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

export class AnonAdminLoginStore implements AuthStore {
    @observable errors: AuthErrorFields = {};
    @IsNotEmpty({ message: "Обязательно для заполнения" })
    @observable
    login = "";
    @IsNotEmpty({ message: "Обязательно для заполнения" })
    @observable
    password = "";

    constructor(private readonly root: RootStore) {}

    @action resetFields() {
        this.login = "";
        this.password = "";
        this.errors = {};
    }

    @action JumpToDashboard() {
        const { routerStore } = this.root;
        routerStore.goTo(AdminRouteNames.mainPage);
    }

    @action async Login() {
        if (this.root.userRpc.isAuthorized) this.JumpToDashboard();

        const errors = await validate(this);
        if (errors.length !== 0) {
            runInAction(() => (this.errors = reduceValidationErrorsToErrors(errors)));
            return;
        }

        const res = await this.root.userRpc.userLogin.loginAdmin(this.login, this.password);
        if (res.success) {
            runInAction(() => {
                this.root.userRpc.setUserToken(res.value);
                this.JumpToDashboard();
                this.resetFields();
            });
            return;
        }

        this.errors.apiError = [res.error.description];
    }

    @action LogOut() {
        this.root.userRpc.resetUserToken();
        window.location.reload();
    }
}
