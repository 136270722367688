import React from "react";
import {Collapse} from "reactstrap";

export interface ChildrenProps {
    children: any;
    style?: any;
}

interface ContentProps extends ChildrenProps{
    collapsed?: boolean,
    style?: any
}

export class InfoBox extends React.Component<ChildrenProps> {
    render() {
        return <div className="ibox">{this.props.children}</div>
    }

    public static Title = function (props: ChildrenProps)
    {
        return <div className="ibox-title"><h4>{props.children}</h4></div>
    };

    public static Header = function (props: ChildrenProps)
    {
        return <div className="ibox-title"><h1>{props.children}</h1></div>
    };

    public static Content = function (props: ContentProps)
    {
        let className = "ibox-content";
        if (props.collapsed != null){
            className += props.collapsed ? " collapse" : "";
        }

        return <div className={className} style={props.style}>{props.children}</div>
    };

    public static Tools = function (props: ChildrenProps)
    {
        return <div className="ibox-tools">{props.children}</div>
    };
}

export class CollapsableInfoBox extends React.Component<ChildrenProps & {header?: any, title?: string, initiallyExpanded?: boolean}, {expanded: boolean}>{
    constructor(props: any, ctx: any)
    {
        super(props, ctx);
        this.state = {expanded: this.props.initiallyExpanded ? this.props.initiallyExpanded : false};
    }
    render()
    {
        return <InfoBox>
            <div onClick={() => {this.setState({expanded: !this.state.expanded});}}
                 className={"info-box_header"}
            >
                {
                    this.props.title
                        ? <InfoBox.Title>
                            <h5> {this.props.title} </h5>
                            <InfoBox.Tools> <i className={`fa fa-chevron-${this.state.expanded ? "up" : "down"}`} />
                            </InfoBox.Tools>
                        </InfoBox.Title>
                        : <InfoBox.Header>{this.props.header}</InfoBox.Header>
                }
            </div>
            <InfoBox.Content style={{ padding: "10px" }}>
                <Collapse in={this.state.expanded}>
                    <div>
                        {this.props.children}
                    </div>
                </Collapse>
            </InfoBox.Content>
        </InfoBox>
    }

}