import {ReactTableStore2} from "src/stores/interfaces/ReactTableStore";
import {GameModeDto, LootBoxDto, LootBoxRollResultDto} from "src/api";
import {observable} from "mobx";
import {RootStore} from "src/stores/RootStore";

export class AdminLootBoxesStore extends ReactTableStore2<LootBoxDto, {}> {
    @observable rootStore: RootStore;
    @observable countToRoll: number;
    @observable resultsVisible: boolean;
    @observable rollResults: LootBoxRollResultDto | null;
    constructor(root: RootStore) {
        super();
        this.rootStore = root;
        this.countToRoll = 1000;
        this.resultsVisible = false;
        this.rollResults = null;
    }

    getFilters(): {} {
        return {};
    }

    async getItemsAsync(search: string, filters: {}, skip: number, take: number): Promise<{
        items: LootBoxDto[];
        itemsTotal: number
    }> 
    {
        const data = await this.rootStore.userRpc.gameContentView.getLootBoxes();
        return {
            items: data,
            itemsTotal: data.length
        };
    }
    
    
    async roll(id: number)
    {
        this.rollResults = await this.rootStore.userRpc.gameContentView.rollLootBox(id, this.countToRoll);
        this.resultsVisible = true;
    }
}
