import { action, computed, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { Result } from "src/api";
import { validate } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";
import { StringMap } from "mobx-state-router/dist/types/router-store";

export abstract class ContentEditorWithArgs<
    TStoreItem extends { id: number },
    TStoreErrors,
    TRouteArguments extends {}
> {
    @observable args: TRouteArguments = {} as any;
    @observable items?: TStoreItem[];
    @observable selectedItemId?: number;
    @observable showSuccessBox: boolean = false;
    @observable showDeleteConfirmation: boolean = false;
    @observable errors?: TStoreErrors;
    @observable error?: string;

    protected constructor(
        protected readonly root: RootStore,
        private readonly returnRoute: string,
        private readonly addItemRoute: string
    ) {}

    protected abstract getAll(): Promise<TStoreItem[]>;
    protected abstract getById(id: number): Promise<TStoreItem | undefined>;
    protected abstract update(): Promise<Result>;
    protected abstract createNew(): Promise<Result>;
    protected abstract deleteById(id: number): Promise<Result>;
    protected abstract resetFields(): void;
    protected abstract setFields(item: TStoreItem): void;
    protected async onLoaded(): Promise<void> {}

    @computed get isItemSelected(): boolean {
        return !!this.selectedItemId || this.selectedItemId == 0;
    }

    @action async load(args?: TRouteArguments): Promise<void> {
        if (args) this.args = args;
        this.error = undefined;
        this.errors = undefined;
        this.showSuccessBox = false;
        this.selectedItemId = undefined;
        this.items = await this.getAll();
        this.resetFields();
        await this.onLoaded();
    }

    @action async loadWithEditor(id: number, args?: TRouteArguments): Promise<void> {
        if (args) this.args = args;
        this.error = undefined;
        this.errors = undefined;
        this.showSuccessBox = false;
        this.selectedItemId = id;
        const item = await this.getById(id);
        if (item) {
            this.setFields(item);
            await this.onLoaded();
        } else {
            await this.root.routerStore.goTo(this.returnRoute);
        }
    }

    @action async save(): Promise<void> {
        const errors = await validate(this);
        if (errors.length != 0) {
            this.errors = reduceValidationErrorsToErrors<TStoreErrors>(errors);
            return;
        }

        const response = await this.update();
        if (response.success) {
            this.resetFields();
            await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
            this.showSuccessBox = true;
        } else {
            this.error = response.error.description;
        }
    }

    @action async add(): Promise<void> {
        const errors = await validate(this);
        if (errors.length != 0) {
            this.errors = reduceValidationErrorsToErrors<TStoreErrors>(errors);
            return;
        }

        const response = await this.createNew();
        if (response.success) {
            this.resetFields();
            await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
            await this.load();
            this.showSuccessBox = true;
        } else {
            this.error = response.error.description;
        }
    }

    @action async removeSelectedItem(): Promise<void> {
        if (this.isItemSelected) {
            this.showDeleteConfirmation = true;
        }
    }

    @action async confirmSelectedItemRemoval(): Promise<void> {
        this.showDeleteConfirmation = false;
        try {
            const response = await this.deleteById(this.selectedItemId!);
            if (response.success) {
                this.resetFields();
                await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
                await this.load();
                this.showSuccessBox = true;
            } else {
                this.error = response.error.description;
            }
        } catch (e) {
            const exception = e.toString();
            if (exception.length > 500) {
                this.error = exception.substring(0, 500);
            } else {
                this.error = exception;
            }
        }
    }

    @action async discardSelectedItemRemoval(): Promise<void> {
        this.showDeleteConfirmation = false;
    }

    @action async goToAdd(): Promise<void> {
        await this.root.routerStore.goTo(this.addItemRoute, this.getRouteArgs());
    }

    @action async goToItems(): Promise<void> {
        await this.root.routerStore.goTo(this.returnRoute, this.getRouteArgs());
    }

    private getRouteArgs(): StringMap {
        return this.args ?? {};
    }
}

export abstract class ContentEditor<TStoreItem extends { id: number }, TStoreErrors> extends ContentEditorWithArgs<
    TStoreItem,
    TStoreErrors,
    {}
> {}
