import {ReactTableStore2} from "src/stores/interfaces/ReactTableStore";
import {observable} from "mobx";
import {RootStore} from "src/stores/RootStore";
import {ServerStatsDto} from "src/api";


export class AnonServerStatsStore
{
    @observable root: RootStore;
    @observable stats?: ServerStatsDto;
    @observable loading = true;
    
    constructor(rootStore: RootStore) 
    {
        this.root = rootStore;
    }
    
    async load(){
        this.loading = true;
        this.stats = await this.root.anonRpc.anon.getServerStats();
        this.loading = false;
    }
}