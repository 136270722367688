import { useRootStore } from "src/utilities";
import { AdminRouteNames, AdminViewMap } from "src/pages/admin/AdminRoutes";
import { useObserver } from "mobx-react";
import { RouterLink, RouterView } from "mobx-state-router";
import * as React from "react";
import style from "../../styles/page.module.css";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "../NavigationSidebar/NavigationSidebar";
import { FC } from "react";
import { Fade } from "reactstrap";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import iconStar from "src/assets/icon/star.svg";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import { AnonAdminLoginStore } from "src/stores/anon/AnonAdminLoginStore";
import { Logo } from "src/components/NewComponents/UI/Logo/Logo";

const AdminNavigation: FC<{ login: AnonAdminLoginStore }> = ({ login }) => (
    <NavigationSidebar>
        <Logo />
        <LocalizedNavigationDelimiter content={"Main"} />
        <RouterLink routeName={AdminRouteNames.mainPage}>
            <img src={iconStar} alt="" className="mr-3" />
            Server Settings
        </RouterLink>
        <RouterLink routeName={AdminRouteNames.gameSettings}>
            <img src={iconStar} alt="" className="mr-3" />
            Game Settings
        </RouterLink>
        <LocalizedNavigationDelimiter content={"Game content"} />
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.gameCharacters}>
            <img src={iconStar} alt="" className="mr-3"/>
            Characters
        </RouterLink>
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.modesAndLevels}>
            <img src={iconStar} alt="" className="mr-3"/>
            Game Modes & Levels
        </RouterLink>
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.lootBoxes}>
            <img src={iconStar} alt="" className="mr-3"/>
            LootBoxes
        </RouterLink>
        <LocalizedNavigationDelimiter content={"Statistics"} />
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.weaponStats}>
            <img src={iconStar} alt="" className="mr-3"/>
            Weapons
        </RouterLink>
        <RouterLink activeClassName={navigationStyle.navigationActive} routeName={AdminRouteNames.levelStats}>
            <img src={iconStar} alt="" className="mr-3"/>
            Levels
        </RouterLink>
        <LocalizedNavigationDelimiter content={"Admin account"} />
        <a href="#" onClick={() => login.LogOut()}>
            <img src={iconLogOut} alt="" className="mr-3" />
            Logout
        </a>
    </NavigationSidebar>
);

export const AdminShell = () => {
    const {
        rootStore: { routerStore, userRpc, anonAdminLoginStore: login },
    } = useRootStore();
    return useObserver(() =>
        userRpc.isAuthorized ? (
            <div className={style.fullHeight}>
                    <div className={style.flexList + " row " + style.fullHeight}>
                        <div className={"col-lg-2 " + style.darkBackground}>
                            <div className={"sticky-top"} style={{ paddingTop: 20 }}>
                                <AdminNavigation login={login} />
                            </div>
                        </div>
                        <div className={"col-lg-10"}>
                            <SuspensePlaceholder>
                                <RouterView routerStore={routerStore} viewMap={AdminViewMap} />
                            </SuspensePlaceholder>
                        </div>
                    </div>
            </div>
        ) : (
            <SuspensePlaceholder>
                <RouterView routerStore={routerStore} viewMap={AdminViewMap} />
            </SuspensePlaceholder>
        )
    );
};
