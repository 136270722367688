import {observable} from "mobx";
import {RootStore} from "src/stores/RootStore";
import {GameLevelDto, GameLevelStats} from "src/api";

export class AdminLevelStatsStore
{
    @observable rootStore : RootStore;
    @observable levels? : GameLevelDto[];
    @observable loading = true;
    @observable loadingLevels = false;
    @observable selectedLevel?: GameLevelDto;
    @observable stats? : GameLevelStats;
    
    constructor(root : RootStore) {
        this.rootStore = root;
    }
    
    async load(){
        this.loadingLevels = true;
        this.levels = await this.rootStore.userRpc.gameContentView.getLevels();
        this.loadingLevels = false;
        if(this.levels.length > 0)
        {
            this.selectedLevel = this.levels[0];
            this.getStats(this.selectedLevel.id);
        }
    }
    
    async getStats(id: number) {
        this.loading = true;
        this.stats = await this.rootStore.userRpc.gameContentView.getLevelStats(id);
        this.loading = false;
    }
}