import {RootStore} from "src/stores/RootStore";
import {observable} from "mobx";
import {DeathmatchSettingsDto, TeamDeathmatchSettingsDto} from "src/api";

export enum GameModeSettingsTab
{
    Deathmatch,
    TeamDeathmatch
}

export class AdminGameSettingsStore {
    
    @observable rootStore: RootStore;
    @observable loading = false;
    @observable deathmatchSettings?: DeathmatchSettingsDto;
    @observable teamDeathmatchSettings? : TeamDeathmatchSettingsDto;
    @observable activeTab : GameModeSettingsTab = GameModeSettingsTab.Deathmatch;
    
    constructor(root: RootStore) {
        this.rootStore = root;
        this.reload();
    }
    
    async reload(){
        this.loading = true;
        this.deathmatchSettings = await this.rootStore.userRpc.gameSettings.getDeathmatchSettings();
        this.teamDeathmatchSettings = await this.rootStore.userRpc.gameSettings.getTeamDeathmatchSettings();
        this.loading = false;
    }
}