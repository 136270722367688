import { ContentEditor } from "src/stores/interfaces/ContentEditorStore";
import { Result } from "src/api";
import { action } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

export class AdminMainStore extends ContentEditor<any, any> {
    constructor(readonly root: RootStore) {
        super(root, AdminRouteNames.mainPage, AdminRouteNames.mainPage);
    }

    protected getAll(): Promise<any[]> {
        return new Promise<any[]>((resolve) =>
            resolve([
                {
                    key : "Auto register users on login",
                    value : true
                },
                {
                    key : "Enable confirmation accounts",
                    value : false
                },
            ])
        );
    }

    protected getById(id: number): Promise<any | undefined> {
        return new Promise<any>((resolve) => resolve({ title: "Hello!" }));
    }

    protected createNew(): Promise<Result> {
        return new Promise<Result>((resolve) =>
            resolve({
                success: true,
                error: { code: "", description: "" },
            })
        );
    }

    protected update(): Promise<Result> {
        return new Promise<Result>((resolve) =>
            resolve({
                success: true,
                error: { code: "", description: "" },
            })
        );
    }

    protected deleteById(id: number): Promise<Result> {
        return new Promise<Result>((resolve) =>
            resolve({
                success: true,
                error: { code: "", description: "" },
            })
        );
    }

    @action protected resetFields(): void {}

    @action protected setFields(item: any): void {}
}
