import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { UserRouteNames, UserViewMap } from "src/pages/user/UserRoutes";
import { useObserver } from "mobx-react";
import { HeaderLink } from "src/components/NewComponents/Header/Header";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { FC } from "react";

export const UserRoutes: FC = () => (
    <>
        <HeaderLink name={UserRouteNames.mainPage} text={"MAIN"} />
        <HeaderLink name={UserRouteNames.profile} text={"PROFILE"} />
    </>
);

export const UserShell = () => {
    const {
        rootStore: { routerStore: store },
    } = useRootStore();
    return useObserver(() => (
        <SuspensePlaceholder>
            <RouterView routerStore={store} viewMap={UserViewMap} />
        </SuspensePlaceholder>
    ));
};
