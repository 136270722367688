import React, {FC} from "react";
import {useObserver} from "mobx-react";
import {HeaderTitleType, Wrapper} from "src/components/NewComponents/Header/Header";
import pageStyles from "src/styles/page.module.css";
import {RouterLink} from "mobx-state-router";
import {AnonRouteNames} from "src/pages/anon/AnonRoutes";
import {useRootStore} from "src/utilities";
import {Col, Row, Table} from "reactstrap";

import styles from './Stats.css'

export const AnonMainPage: FC = () => {
    const {
        anonMainStore : store
    } = useRootStore().rootStore;
    return useObserver(() => (
        <Wrapper
            entry={{
                showFooter: true,
                type: HeaderTitleType.text,
                title: "Main Page",
                subtitle: "Welcome to D.E.S.P.A. staging server!",
            }}
        >
            <div className={pageStyles.page__maxWidth + " container mt-3"}>
                <h1 className={pageStyles.heading}>Welcome to D.E.S.P.A. server</h1>
                <p>This is temporary page for D.E.S.P.A. server. For now here will be available information about servers and download links for latest client version!</p>
                <Table title={"Server statistics"}>
                    <Row>
                       <Col>Total registered users:</Col>
                       <Col>{store.stats?.totalUsers}</Col> 
                    </Row>
                    <Row>
                        <Col>Total played sessions:</Col>
                        <Col>{store.stats?.totalPlayedSessions}</Col>
                    </Row>
                    <Row>
                        <Col>Currently running sessions:</Col>
                        <Col>{store.stats?.currentlyRunningSessions}</Col>
                    </Row>
                </Table>
                <p>For more information please <RouterLink routeName={AnonRouteNames.userLogin}>login</RouterLink> to your account to view statistics and played sessions.</p>

                <p><i>For now links is not available! We will add them ASAP.</i></p>
            </div>
        </Wrapper>
    ));
};
