export interface ServerStatsDto {
        totalUsers : number;
        totalPlayedSessions : number;
        currentlyRunningSessions : number;
}

export interface WeaponStats {
        weaponId : number;
        countOfShoots : number;
        successShoots : number;
        accuracyRate : number;
        instigatedDamage : number;
}

export interface UserStatsTable {
        totalKills : number;
        totalDeaths : number;
        totalShoots : number;
        totalHits : number;
        averageAccuracy : number;
        totalDamageInstigated : number;
        totalDamageReceived : number;
        weaponStats : WeaponStats[];
}

export interface UserInfoDto {
        userId : number;
        nickname : string;
        selectedCharacter : string;
        experience : number;
        level : number;
        coinsCount : number;
        inventoryState : string;
}

export interface ContentReferenceDto {
        id : number;
        name : string;
}

export interface FavoriteWeaponInfoDto {
        name : string;
        accuracy : number;
        damage : number;
}

export interface UserSessionAndStatsDto {
        playedSessions : number;
        wonSessions : number;
        winRate : number;
        favoriteLevel : ContentReferenceDto;
        favoriteWeapon : FavoriteWeaponInfoDto;
        favoriteGameMode : ContentReferenceDto;
}

export interface ErrorCode {
        code : string;
        description : string;
}

export interface ResultT<T> {
        value : T;
        success : boolean;
        error : ErrorCode;
}

export interface UserProfileDto {
        id : number;
        email : string;
        name : string;
        avatarImage : number;
}

export interface Result {
        success : boolean;
        error : ErrorCode;
}

export interface GameCharacterDto {
        id : number;
        name : string;
        description : string;
        systemName : string;
        isDefault : boolean;
}

export interface GameModeDto {
        id : number;
        name : string;
        description : string;
        allowBots : boolean;
}

export interface GameLevelDto {
        id : number;
        name : string;
        displayName : string;
        maxPlayers : number;
        gameMode : GameModeDto;
}

export interface WeaponUsageStatsItemDto {
        userName : string;
        totalShoots : number;
        successShoots : number;
        totalDamage : number;
}

export interface WeaponAverageStats {
        weaponId : number;
        shoots : number;
        hits : number;
        damage : number;
}

export interface GameLevelStats {
        playedSessions : number;
        abortedSessions : number;
        averageUserKills : number;
        averageUserDeaths : number;
        averageLifetime : number;
        popularWeaponId : number;
        uniqueUsersPlayed : number;
        weaponStats : WeaponAverageStats[];
}

export interface SessionUserStats {
        kills : number;
        deaths : number;
        receivedDamage : number;
        damageDealt : number;
        bestWeaponId : number;
        bestWeaponShoots : number;
        bestWeaponHits : number;
        bestWeaponDamage : number;
        isWinner : boolean;
        gainedExp : number;
        currentExperience : number;
        gainedLevels : number;
        averageLifetime : number;
}

export interface SessionTableItem {
        userId : number;
        name : string;
        kills : number;
        deaths : number;
        participantInfo : string;
}

export interface GameSessionAchievement {
        type : number;
        count : number;
}

export interface GameSessionResult {
        sessionId : number;
        levelSystemName : string;
        myStats : SessionUserStats;
        table : SessionTableItem[];
        achievementsGot : GameSessionAchievement[];
}

export interface LootBoxDto {
        id : number;
        name : string;
}

export interface LootBoxRollRewardDto {
        name : string;
        count : number;
}

export interface LootBoxRollResultDto {
        resultItems : LootBoxRollRewardDto[];
}

export interface DeathmatchSettingsDto {
        matchTime : number;
}

export interface TeamDeathmatchSettingsDto {
        matchTime : number;
        fragLimit : number;
        teamNames : string;
}

export interface UserRoleDto {
        id : number;
        userId : number;
        role : string;
}


export interface ICoreRpcProxyForAnonRpc {
    getServerStats () : Promise<ServerStatsDto>;
}
export class CoreRpcProxyForAnonRpc implements ICoreRpcProxyForAnonRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getServerStats () : Promise<ServerStatsDto>    {
        return this.parent.send<ServerStatsDto>({Target: 'AnonRpc', MethodSignature: 'DkdldFNlcnZlclN0YXRz', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserInfoRpc {
    getUserStats () : Promise<UserStatsTable>;
    getUserSettings () : Promise<UserInfoDto>;
    getSummary () : Promise<UserSessionAndStatsDto>;
}
export class CoreRpcProxyForUserInfoRpc implements ICoreRpcProxyForUserInfoRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getUserStats () : Promise<UserStatsTable>    {
        return this.parent.send<UserStatsTable>({Target: 'UserInfoRpc', MethodSignature: 'DEdldFVzZXJTdGF0cw==', Arguments: [] });
    }
    public getUserSettings () : Promise<UserInfoDto>    {
        return this.parent.send<UserInfoDto>({Target: 'UserInfoRpc', MethodSignature: 'D0dldFVzZXJTZXR0aW5ncw==', Arguments: [] });
    }
    public getSummary () : Promise<UserSessionAndStatsDto>    {
        return this.parent.send<UserSessionAndStatsDto>({Target: 'UserInfoRpc', MethodSignature: 'CkdldFN1bW1hcnk=', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserLoginRpc {
    login (username : string, password : string) : Promise<ResultT<string>>;
    register (email : string, password : string) : Promise<ResultT<UserProfileDto>>;
    resendConfirmationCode (email : string, password : string) : Promise<Result>;
    confirmAccount (code : string) : Promise<ResultT<string>>;
    resetPassword (email : string) : Promise<Result>;
    changePassword (code : string, password : string) : Promise<ResultT<string>>;
    loginAdmin (username : string, password : string) : Promise<ResultT<string>>;
    checkAuthToken () : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public login (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'BUxvZ2luDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [username,password] });
    }
    public register (email : string, password : string) : Promise<ResultT<UserProfileDto>>    {
        return this.parent.send<ResultT<UserProfileDto>>({Target: 'UserLoginRpc', MethodSignature: 'CFJlZ2lzdGVyDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [email,password] });
    }
    public resendConfirmationCode (email : string, password : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'FlJlc2VuZENvbmZpcm1hdGlvbkNvZGUNU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [email,password] });
    }
    public confirmAccount (code : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'DkNvbmZpcm1BY2NvdW50DVN5c3RlbS5TdHJpbmc=', Arguments: [code] });
    }
    public resetPassword (email : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserLoginRpc', MethodSignature: 'DVJlc2V0UGFzc3dvcmQNU3lzdGVtLlN0cmluZw==', Arguments: [email] });
    }
    public changePassword (code : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'DkNoYW5nZVBhc3N3b3JkDVN5c3RlbS5TdHJpbmcNU3lzdGVtLlN0cmluZw==', Arguments: [code,password] });
    }
    public loginAdmin (username : string, password : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'CkxvZ2luQWRtaW4NU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [username,password] });
    }
    public checkAuthToken () : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DkNoZWNrQXV0aFRva2Vu', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserProfileRpc {
    getProfile () : Promise<UserProfileDto>;
    updateProfile (profile : UserProfileDto) : Promise<Result>;
    uploadUserPhoto (data : number[]) : Promise<Result>;
    removeUserPhoto () : Promise<Result>;
}
export class CoreRpcProxyForUserProfileRpc implements ICoreRpcProxyForUserProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getProfile () : Promise<UserProfileDto>    {
        return this.parent.send<UserProfileDto>({Target: 'UserProfileRpc', MethodSignature: 'CkdldFByb2ZpbGU=', Arguments: [] });
    }
    public updateProfile (profile : UserProfileDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'DVVwZGF0ZVByb2ZpbGUjU3R1Y2suU2VydmVyLldlYi5EdG8uVXNlclByb2ZpbGVEdG8=', Arguments: [profile] });
    }
    public uploadUserPhoto (data : number[]) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1VwbG9hZFVzZXJQaG90b4sBU3lzdGVtLkNvbGxlY3Rpb25zLkdlbmVyaWMuTGlzdGAxW1tTeXN0ZW0uQnl0ZSwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [data] });
    }
    public removeUserPhoto () : Promise<Result>    {
        return this.parent.send<Result>({Target: 'UserProfileRpc', MethodSignature: 'D1JlbW92ZVVzZXJQaG90bw==', Arguments: [] });
    }
}
export interface ICoreRpcProxyForGameContentEditRpc {
    editCharacter (dto : GameCharacterDto) : Promise<void>;
    characterSetDefault (id : number) : Promise<void>;
    editGameMode (dto : GameModeDto) : Promise<void>;
    editLevel (level : GameLevelDto) : Promise<void>;
}
export class CoreRpcProxyForGameContentEditRpc implements ICoreRpcProxyForGameContentEditRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public editCharacter (dto : GameCharacterDto) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameContentEditRpc', MethodSignature: 'DUVkaXRDaGFyYWN0ZXIlU3R1Y2suU2VydmVyLldlYi5EdG8uR2FtZUNoYXJhY3RlckR0bw==', Arguments: [dto] });
    }
    public characterSetDefault (id : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameContentEditRpc', MethodSignature: 'E0NoYXJhY3RlclNldERlZmF1bHQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public editGameMode (dto : GameModeDto) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameContentEditRpc', MethodSignature: 'DEVkaXRHYW1lTW9kZSBTdHVjay5TZXJ2ZXIuV2ViLkR0by5HYW1lTW9kZUR0bw==', Arguments: [dto] });
    }
    public editLevel (level : GameLevelDto) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameContentEditRpc', MethodSignature: 'CUVkaXRMZXZlbCFTdHVjay5TZXJ2ZXIuV2ViLkR0by5HYW1lTGV2ZWxEdG8=', Arguments: [level] });
    }
}
export interface ICoreRpcProxyForGameContentViewRpc {
    getCharacters () : Promise<GameCharacterDto[]>;
    getGameModes () : Promise<GameModeDto[]>;
    getLevels () : Promise<GameLevelDto[]>;
    getWeaponStats (weaponId : number) : Promise<WeaponUsageStatsItemDto[]>;
    getLevelStats (id : number) : Promise<GameLevelStats>;
    getSessionResults (sessionId : number, userId : number) : Promise<GameSessionResult>;
    getLootBoxes () : Promise<LootBoxDto[]>;
    rollLootBox (id : number, count : number) : Promise<LootBoxRollResultDto>;
}
export class CoreRpcProxyForGameContentViewRpc implements ICoreRpcProxyForGameContentViewRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getCharacters () : Promise<GameCharacterDto[]>    {
        return this.parent.send<GameCharacterDto[]>({Target: 'GameContentViewRpc', MethodSignature: 'DUdldENoYXJhY3RlcnM=', Arguments: [] });
    }
    public getGameModes () : Promise<GameModeDto[]>    {
        return this.parent.send<GameModeDto[]>({Target: 'GameContentViewRpc', MethodSignature: 'DEdldEdhbWVNb2Rlcw==', Arguments: [] });
    }
    public getLevels () : Promise<GameLevelDto[]>    {
        return this.parent.send<GameLevelDto[]>({Target: 'GameContentViewRpc', MethodSignature: 'CUdldExldmVscw==', Arguments: [] });
    }
    public getWeaponStats (weaponId : number) : Promise<WeaponUsageStatsItemDto[]>    {
        return this.parent.send<WeaponUsageStatsItemDto[]>({Target: 'GameContentViewRpc', MethodSignature: 'DkdldFdlYXBvblN0YXRzDFN5c3RlbS5JbnQzMg==', Arguments: [weaponId] });
    }
    public getLevelStats (id : number) : Promise<GameLevelStats>    {
        return this.parent.send<GameLevelStats>({Target: 'GameContentViewRpc', MethodSignature: 'DUdldExldmVsU3RhdHMMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public getSessionResults (sessionId : number, userId : number) : Promise<GameSessionResult>    {
        return this.parent.send<GameSessionResult>({Target: 'GameContentViewRpc', MethodSignature: 'EUdldFNlc3Npb25SZXN1bHRzDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [sessionId,userId] });
    }
    public getLootBoxes () : Promise<LootBoxDto[]>    {
        return this.parent.send<LootBoxDto[]>({Target: 'GameContentViewRpc', MethodSignature: 'DEdldExvb3RCb3hlcw==', Arguments: [] });
    }
    public rollLootBox (id : number, count : number) : Promise<LootBoxRollResultDto>    {
        return this.parent.send<LootBoxRollResultDto>({Target: 'GameContentViewRpc', MethodSignature: 'C1JvbGxMb290Qm94DFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [id,count] });
    }
}
export interface ICoreRpcProxyForGameSettingsRpc {
    getDeathmatchSettings () : Promise<DeathmatchSettingsDto>;
    getTeamDeathmatchSettings () : Promise<TeamDeathmatchSettingsDto>;
    applyDeathmatchSettings (dto : DeathmatchSettingsDto) : Promise<void>;
    applyTeamDeathmatchSettings (dto : TeamDeathmatchSettingsDto) : Promise<void>;
}
export class CoreRpcProxyForGameSettingsRpc implements ICoreRpcProxyForGameSettingsRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getDeathmatchSettings () : Promise<DeathmatchSettingsDto>    {
        return this.parent.send<DeathmatchSettingsDto>({Target: 'GameSettingsRpc', MethodSignature: 'FUdldERlYXRobWF0Y2hTZXR0aW5ncw==', Arguments: [] });
    }
    public getTeamDeathmatchSettings () : Promise<TeamDeathmatchSettingsDto>    {
        return this.parent.send<TeamDeathmatchSettingsDto>({Target: 'GameSettingsRpc', MethodSignature: 'GUdldFRlYW1EZWF0aG1hdGNoU2V0dGluZ3M=', Arguments: [] });
    }
    public applyDeathmatchSettings (dto : DeathmatchSettingsDto) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameSettingsRpc', MethodSignature: 'F0FwcGx5RGVhdGhtYXRjaFNldHRpbmdzMFN0dWNrLlNlcnZlci5XZWIuRHRvLk1vZGVzLkRlYXRobWF0Y2hTZXR0aW5nc0R0bw==', Arguments: [dto] });
    }
    public applyTeamDeathmatchSettings (dto : TeamDeathmatchSettingsDto) : Promise<void>    {
        return this.parent.send<void>({Target: 'GameSettingsRpc', MethodSignature: 'G0FwcGx5VGVhbURlYXRobWF0Y2hTZXR0aW5nczRTdHVjay5TZXJ2ZXIuV2ViLkR0by5Nb2Rlcy5UZWFtRGVhdGhtYXRjaFNldHRpbmdzRHRv', Arguments: [dto] });
    }
}
export interface ICoreRpcProxyForRolesRpc {
    getMyRoles () : Promise<string[]>;
    getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>;
}
export class CoreRpcProxyForRolesRpc implements ICoreRpcProxyForRolesRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getMyRoles () : Promise<string[]>    {
        return this.parent.send<string[]>({Target: 'RolesRpc', MethodSignature: 'CkdldE15Um9sZXM=', Arguments: [] });
    }
    public getUserRolesByIds (ids : number[]) : Promise<UserRoleDto[]>    {
        return this.parent.send<UserRoleDto[]>({Target: 'RolesRpc', MethodSignature: 'EUdldFVzZXJSb2xlc0J5SWRzjAFTeXN0ZW0uQ29sbGVjdGlvbnMuR2VuZXJpYy5MaXN0YDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [ids] });
    }
}
export interface ICoreApi {
    anon : ICoreRpcProxyForAnonRpc;
    userInfo : ICoreRpcProxyForUserInfoRpc;
    userLogin : ICoreRpcProxyForUserLoginRpc;
    userProfile : ICoreRpcProxyForUserProfileRpc;
    gameContentEdit : ICoreRpcProxyForGameContentEditRpc;
    gameContentView : ICoreRpcProxyForGameContentViewRpc;
    gameSettings : ICoreRpcProxyForGameSettingsRpc;
    roles : ICoreRpcProxyForRolesRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.anon = new CoreRpcProxyForAnonRpc(this);
    this.userInfo = new CoreRpcProxyForUserInfoRpc(this);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.userProfile = new CoreRpcProxyForUserProfileRpc(this);
    this.gameContentEdit = new CoreRpcProxyForGameContentEditRpc(this);
    this.gameContentView = new CoreRpcProxyForGameContentViewRpc(this);
    this.gameSettings = new CoreRpcProxyForGameSettingsRpc(this);
    this.roles = new CoreRpcProxyForRolesRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    anon : CoreRpcProxyForAnonRpc;
    userInfo : CoreRpcProxyForUserInfoRpc;
    userLogin : CoreRpcProxyForUserLoginRpc;
    userProfile : CoreRpcProxyForUserProfileRpc;
    gameContentEdit : CoreRpcProxyForGameContentEditRpc;
    gameContentView : CoreRpcProxyForGameContentViewRpc;
    gameSettings : CoreRpcProxyForGameSettingsRpc;
    roles : CoreRpcProxyForRolesRpc;
}
