import React from "react";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { Route, RouterState } from "mobx-state-router";
import { RootStore } from "src/stores/RootStore";
import {
    AnonNotFoundPage,
    AnonUserRegisterPage,
    AnonUserLoginPage,
    AnonAdminLoginPage,
} from "src/pages/anon/AnonLazyRoutes";
import { UserRouteNames } from "src/pages/user/UserRoutes";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";
import {AnonMainPage} from "src/pages/anon/Main/AnonMainPage";

const AnonAuthorizedRedirectHook: (
    getRouteName: (root: RootStore) => string,
    checkAuth: (root: RootStore) => boolean
) => RouteTransitionHook = (getRouteName, checkAuth) => async (root, _, to) => {
    if (!checkAuth(root)) return;
    const routeName = getRouteName(root);
    if (!routeName) return;
    const state = new RouterState(routeName, to.params, to.queryParams);
    const route = root.routerStore.getRoute(routeName);
    if (route.onEnter) await route.onEnter(to, state, root.routerStore);
    throw state;
};

export enum AnonRouteNames {
    main = 'anon-main',
    notFound = "not-found",
    userLogin = "anon-user-login",
    userRegister = "anon-user-register",
    adminLogin = "anon-admin-login",
}

export const AnonViewMap = {
    [AnonRouteNames.main]: <AnonMainPage/>,
    [AnonRouteNames.notFound]: <AnonNotFoundPage />,
    [AnonRouteNames.userLogin]: <AnonUserLoginPage />,
    [AnonRouteNames.userRegister]: <AnonUserRegisterPage />,
    [AnonRouteNames.adminLogin]: <AnonAdminLoginPage />,
};

export const AnonRoutes: Route[] = convertRoutes([
    {
        pattern: "/",
        name: AnonRouteNames.main,
        hooks : [
            AnonAuthorizedRedirectHook(
                () => UserRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            )
        ],
        onEnter : root => root.anonMainStore.load()
    },
    {
        pattern: "/not-found",
        name: AnonRouteNames.notFound,
    },
    {
        pattern: "/register",
        name: AnonRouteNames.userRegister,
        hooks: [
            AnonAuthorizedRedirectHook(
                () => UserRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            ),
        ],
    },
    {
        pattern: "/login",
        name: AnonRouteNames.userLogin,
        hooks: [
            AnonAuthorizedRedirectHook(
                () => UserRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            ),
        ],
    },
    {
        pattern: "/admin/login",
        name: AnonRouteNames.adminLogin,
        hooks: [
            AnonAuthorizedRedirectHook(
                () => AdminRouteNames.mainPage,
                (root) => root.userRpc.isAuthorized
            ),
        ],
    },
]);
