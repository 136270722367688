import { action, computed, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { CoreApi } from "src/api";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { Routes } from "src/routes";
import { PreviewBoxStore } from "src/components/PreviewBox/PreviewBoxStore";
import { AnonUserLoginStore } from "src/stores/anon/AnonUserLoginStore";
import { AnonUserRegisterStore } from "src/stores/anon/AnonUserRegisterStore";
import { AnonUserConfirmAccountStore } from "src/stores/anon/AnonUserConfirmAccountStore";
import { AnonAdminLoginStore } from "src/stores/anon/AnonAdminLoginStore";
import { AdminMainStore } from "src/stores/admin/AdminMainStore";
import { UserMainStore } from "src/stores/user/UserMainStore";
import {AdminGameCharactersStore} from "src/stores/admin/AdminGameCharactersStore";
import {AdminWeaponStatsStore} from "src/stores/admin/AdminWeaponStatsStore";
import {
    AdminGameLevelsStore,
    AdminGameModesStore,
    AdminModesAndLevelsStore
} from "src/stores/admin/AdminGameModesStore";
import {AnonServerStatsStore} from "src/stores/anon/AnonServerStatsStore";
import {AdminGameSettingsStore} from "src/stores/admin/AdminGameSettingsStore";
import {AdminLevelStatsStore} from "src/stores/admin/AdminLevelStatsStore";
import {AdminSessionStatsStore} from "src/stores/admin/AdminSessionStatsStore";
import {AdminLootBoxesStore} from "src/stores/admin/AdminLootBoxesStore";

type AuthHeaderKeys = "X-User-Auth" | "X-Admin-Auth" | "X-Jury-Auth";

const apiUrl = "/tsrpc";

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null;
    private readonly localStorageKey;

    @computed get isAuthorized() {
        return this.token != null;
    }

    constructor(path: string, authHeaderKey: AuthHeaderKeys) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = {};
            if (this.token) request.headers[authHeaderKey] = this.token;
            const res = await fetch(url, request);
            if (res.status == 401) {
                window.location.reload();
                this.resetUserToken();
                await new Promise(() => {
                    // Never
                });
            }
            return res;
        });
        const localStorageKey = "vote-auth-token:" + authHeaderKey;
        this.localStorageKey = localStorageKey;
        this.token = window.localStorage.getItem(localStorageKey);
    }

    @action setUserToken(token: string) {
        this.token = token;
        window.localStorage.setItem(this.localStorageKey, token);
    }

    @action resetUserToken() {
        this.token = null;
        window.localStorage.removeItem(this.localStorageKey);
    }
}

export class RootStore {
    @observable userRpc = new SecureCoreApi(apiUrl, "X-User-Auth");
    @observable anonRpc = new CoreApi(apiUrl);
    @observable routerStore = new RouterStore(this, Routes, new RouterState("not-found"));
    @observable previewStore = new PreviewBoxStore();

    // Anon routes.
    @observable anonMainStore = new AnonServerStatsStore(this);
    @observable anonAdminLoginStore = new AnonAdminLoginStore(this);
    @observable anonUserLoginStore = new AnonUserLoginStore(this);
    @observable anonUserRegisterStore = new AnonUserRegisterStore(this);
    @observable anonUserConfirmAccountStore = new AnonUserConfirmAccountStore(this);

    // User specific routes
    @observable userProfileStore = new UserProfileStore(this);
    @observable userMainStore = new UserMainStore(this);

    // Admin specific routes
    @observable adminMainStore = new AdminMainStore(this);
    @observable adminGameCharactersStore = new AdminGameCharactersStore(this);
    @observable adminWeaponsStatsStore = new AdminWeaponStatsStore(this);
    @observable adminGameModesStore = new AdminGameModesStore(this);
    @observable adminGameLevelsStore = new AdminGameLevelsStore(this);
    @observable adminModesAndLevelsPageStore = new AdminModesAndLevelsStore(this);
    @observable adminGameSettingsStore = new AdminGameSettingsStore(this);
    
    @observable adminLevelStatsStore = new AdminLevelStatsStore(this);
    
    @observable adminSessionInfoStore = new AdminSessionStatsStore(this);
    
    @observable adminLootBoxesStore = new AdminLootBoxesStore(this);
}
