import {DeathmatchSettingsDto} from "src/api";
import React, {FC} from "react";
import {useLocalStore, useObserver} from "mobx-react";
import {useRootStore} from "src/utilities";
import {InfoBox} from "src/components/InfoBox";
import {Button, Col, Row, Table} from "reactstrap";
import {NumericField} from "src/components/NumericInput";
import {LoadingIfNull} from "src/components/Loading";

const SettingsEditor : FC<{item: DeathmatchSettingsDto, onChange: (x: DeathmatchSettingsDto) => void}> = (props, onChange) => {
    const store = useLocalStore<Omit<DeathmatchSettingsDto, 'matchTime'> & {matchTime: number | undefined}>(() => ({...props.item}));
    return useObserver(() => (
        <Table>
            <tr>
                <td width={"25%"}>Match time (seconds):</td>
                <td>
                    <NumericField value={store.matchTime} onChange={n => store.matchTime = n}/>
                </td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: "center"}}>
                    <div className={"save-button"}>
                        <Button className={"buttonDarkBlue"} disabled={store.matchTime == undefined} onClick={() => props.onChange({matchTime : store.matchTime!})}>Save</Button>
                    </div>
                </td>
            </tr>
        </Table>
    ));
}

export const ModeDeathmatchSettings: FC = () => {
    const{
        rootStore : {adminGameSettingsStore : store}
    } = useRootStore();
    return useObserver(() => (
        <LoadingIfNull>{store.loading || store.deathmatchSettings == undefined ? null : 
            <SettingsEditor item={store.deathmatchSettings!} onChange={async x => {
                await store.rootStore.userRpc.gameSettings.applyDeathmatchSettings(x);
            }}/>}
        </LoadingIfNull>
    ));
};