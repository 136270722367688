import React, {FC} from "react";
import {Input} from "reactstrap";
import pageStyles from "src/styles/page.module.css";

export type NumericFieldProps = {
    value?: number;
    onChange: (x?: number) => void;
};

export const NumericField : FC<NumericFieldProps> = ({value, onChange}) => {
  return (
          <Input value={value ?? ""}
                 onChange={e => {
                     if(e.target.value.length > 0 && e.target.value.match("[0-9]+")?.length != 0)
                     {
                         let num = Number.parseInt(e.target.value);
                         onChange(num);
                     }
                     else
                     {
                        onChange(undefined);    
                     }
                 }}
                 className={value == undefined ? pageStyles.inputError : pageStyles.inputGrey}
                 type="text"
                 placeholder='0'/>
  );
};
