import React, {FC, useState} from "react";
import {GameLevelDto, GameLevelStats} from "src/api";
import {NumericField} from "src/components/NumericInput";
import {Button, Table} from "reactstrap";
import {useRootStore} from "src/utilities";
import {useObserver} from "mobx-react";
import {LoadingIf} from "src/components/Loading";
import {AutoCompleteSelect} from "src/components/ValidatedInput/ValidatedSelect";
import Select from "react-select";
import {InfoBox} from "src/components/InfoBox";
import styles from "src/styles/page.module.css"

const Weapons = ["None", "Shotgun", "Rocketgun", "Gauss Canon", "SMG"];

const LevelStatsView : FC<GameLevelStats> = ((props) => {
    const s: GameLevelStats = props;
    return (  
        <div>
            <Table className={styles.tbStats}>
                <tr><td className={styles.tbName} width={"25%"}>Played sessions:</td><td className={styles.tbValue}>{s.playedSessions}</td></tr>
                <tr><td className={styles.tbName} width={"25%"}>Aborted sessions:</td><td className={styles.tbValue}>{s.abortedSessions}</td></tr>
                <tr><td className={styles.tbName} width={"25%"}>Unique players:</td><td className={styles.tbValue}>{s.uniqueUsersPlayed}</td></tr>
                <tr><td className={styles.tbName} width={"25%"}>Average per session kills:</td><td className={styles.tbValue}>{s.averageUserKills}</td></tr>
                <tr><td className={styles.tbName} width={"25%"}>Average per session deaths:</td><td className={styles.tbValue}>{s.averageUserDeaths}</td></tr>
                <tr><td className={styles.tbName} width={"25%"}>Average per session lifetime:</td><td className={styles.tbValue}>{s.averageLifetime.toFixed(2)} s</td></tr>
                {s.weaponStats!.map(x => 
                    <tr>
                        <td colSpan={2} style={{padding : 0}}>
                            <Table className={styles.tbStats}>
                                <tr><td className={styles.tbHead} colSpan={2}>{Weapons[x.weaponId] ?? "asd"}</td></tr>
                                <tr><td className={styles.tbName} width={"25%"}>Average shoots:</td><td className={styles.tbValue}>{x.shoots}</td></tr>
                                <tr><td className={styles.tbName} width={"25%"}>Average hits:</td><td className={styles.tbValue}>{x.hits}</td></tr>
                                <tr><td className={styles.tbName} width={"25%"}>Average damage:</td><td className={styles.tbValue}>{x.damage.toFixed(2)}</td></tr>
                            </Table>
                        </td>
                    </tr>
                )}
            </Table>
        </div>
    );
});

export const AdminLevelStatsPage : FC = (() => {
    const {
        rootStore : { adminLevelStatsStore : store }
    } = useRootStore();
    const [level, setLevel] = useState(store.selectedLevel);
    return useObserver(() => (
        <div>
            <InfoBox.Header>Level statistics</InfoBox.Header>
            <InfoBox.Content>
                <Table>
                    <tr><td><h5>Select level: </h5></td>
                        <td>
                            <select multiple={false} onChange={e => store.getStats(parseInt(e.target.value))}>
                                {store.levels!.map(x => <option value={x.id}>{x.displayName}</option>)}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <LoadingIf isLoading={store.loading}>
                                <LevelStatsView {...store.stats!}/>
                            </LoadingIf>
                        </td>
                    </tr>
                </Table>
            </InfoBox.Content>
        </div> 
    ));
});