import {observer} from "mobx-react";
import ReactTable, {Column} from "react-table"
import {IReactTableStore} from "src/stores/interfaces/ReactTableStore";
import React from "react";

export interface TypedColumn<T> extends Column{
    accessor: (row: T) => any;
}

@observer export class StandardReactTable<T> extends React.Component<{
    columns: TypedColumn<T>[],
    store: IReactTableStore,
    subComponent?: (row: T) => any
}>
{
    render()
    {
        this.props.store.ensureLoaded();
        const props = this.props;
        return <ReactTable
            columns={props.columns.map(col=>{
                const old = col.accessor;
                const Observer = observer(function (props: { data: T }) {
                    return old(props.data);
                });
                col.accessor = (data: T) => <Observer data={data}/>;
                return col;
            })}
            manual
            data={props.store.items}
            pageSize={props.store.pageSize}
            pages={props.store.totalPages}
            loading={props.store.isLoading}
            onPageSizeChange={(size, page) => props.store.setPageSize(size, page)}
            onPageChange={(page) => props.store.setPageSize(props.store.pageSize, page)}
            SubComponent={(props.subComponent != null) ? (row => props.subComponent!(row.original)) : undefined}
            className="-striped -highlight"/>;
    }
}