import {observable} from "mobx";
import {RootStore} from "src/stores/RootStore";

export class AdminSessionStatsStore
{
    @observable rootStore : RootStore;
    constructor(root: RootStore) {
        this.rootStore = root;
    }
    
    async load(id: number, user: number)
    {
        await this.rootStore.userRpc.gameContentView.getSessionResults(id, user);
    }
}