import React, {FC} from "react";
import {useRootStore} from "src/utilities";
import {useObserver} from "mobx-react";
import {InfoBox} from "src/components/InfoBox";
import {ModeDeathmatchSettings} from "src/pages/admin/Settings/ModeDeathmatchSettings";
import {ModeTeamDeathmatchSettings} from "src/pages/admin/Settings/ModeTeamDeathmatchSettings";
import {GameModeSettingsTab} from "src/stores/admin/AdminGameSettingsStore";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {AdminModesAndLevelsTab} from "src/stores/admin/AdminGameModesStore";

export const AdminGameSettingsPage : FC = () => {
    const {
         rootStore : {adminGameSettingsStore: store}
    } = useRootStore();
    const toggle = (tab: GameModeSettingsTab) => 
    {
        store.activeTab = tab;
    }
    return useObserver(() => (
        <div>
            <InfoBox.Header>Game settings</InfoBox.Header> 
            <InfoBox.Content>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={store.activeTab == GameModeSettingsTab.Deathmatch}
                            onClick={() => toggle(GameModeSettingsTab.Deathmatch)}>Deathmatch</NavLink>
                        <NavLink active={store.activeTab == GameModeSettingsTab.TeamDeathmatch}
                                 onClick={() => toggle(GameModeSettingsTab.TeamDeathmatch)}>Team deathmatch</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={store.activeTab}>
                    <TabPane tabId={GameModeSettingsTab.Deathmatch}>
                        <ModeDeathmatchSettings />
                    </TabPane>
                    <TabPane tabId={GameModeSettingsTab.TeamDeathmatch}>
                        <ModeTeamDeathmatchSettings/>
                    </TabPane>
                </TabContent>
               
                
            </InfoBox.Content>
        </div>
    ));
}