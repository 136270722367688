import {ReactTableStore2} from "src/stores/interfaces/ReactTableStore";
import {GameCharacterDto, WeaponUsageStatsItemDto} from "src/api";
import {observable} from "mobx";
import {RootStore} from "src/stores/RootStore";


export class AdminWeaponStatsItemStore extends ReactTableStore2<WeaponUsageStatsItemDto, { }>
{
    @observable rootStore : RootStore;
    
    constructor(weaponId: number, readonly store : RootStore) {
        super();
        this.weaponId = weaponId;
        this.rootStore = store;
    }
    @observable weaponId: number;
    getFilters(): {} {
        return {};
    }

    async getItemsAsync(search: string, filters: {}, skip: number, take: number): Promise<{ items: WeaponUsageStatsItemDto[]; itemsTotal: number }> {
        let items = await this.rootStore.userRpc.gameContentView.getWeaponStats(this.weaponId);
        return {
            items : items,
            itemsTotal : items.length
        };
    }
    
}

export enum AdminWeaponId
{
    SMG = 4,
    Shotgun = 1,
    Rocketgun = 2,
    Railgun = 3,
}

export class AdminWeaponStatsStore
{
    @observable rootStore : RootStore;
    @observable shotgunStore : ReactTableStore2<WeaponUsageStatsItemDto, {}>;
    @observable rocketgunStore : ReactTableStore2<WeaponUsageStatsItemDto, {}>;
    @observable railgunStore : ReactTableStore2<WeaponUsageStatsItemDto, {}>;
    @observable smgStore : ReactTableStore2<WeaponUsageStatsItemDto, {}>;
    @observable activeTab : AdminWeaponId;
    
    constructor(readonly store : RootStore) {
        this.rootStore = store;
        this.activeTab = AdminWeaponId.SMG;
        this.shotgunStore = new AdminWeaponStatsItemStore(1, store);
        this.rocketgunStore = new AdminWeaponStatsItemStore(2, store);
        this.railgunStore = new AdminWeaponStatsItemStore(3, store);
        this.smgStore = new AdminWeaponStatsItemStore(4, store);
    }
}