import {action, computed, observable, reaction} from "mobx";
import { Loadable } from "src/stores/interfaces/LoadableStore";
import {RequestTracking} from "src/utils/RequestTracking";

export interface IReactTableStore {
    totalPages: number;
    pageSize: number;
    isLoading: boolean;
    items: any;
    wantsPagination() : boolean;
    ensureLoaded() : void;
    setPageSize(size: number, page: number): void;
}

export abstract class ReactTableStore2<T, TFilters> extends RequestTracking implements  IReactTableStore{
    @observable page: number;
    @observable pageSize: number;
    @observable totalPages: number = 0;
    @observable items: T[] = [];
    @observable search: string = "";
    @observable private __forcedRefreshToken: number = 0;
    private __loaderInitialized : boolean = false;
    private __loadedFor = "";
    private __loadingFor = "";

    constructor() {
        super();
        this.page = 0;
        this.pageSize = 10;
        // Reset the current page on filter change
        reaction(() => ({search: this.search, filters: this.getFilters()}),
            () => this.page = 0, {
                fireImmediately: false
            });
    }

    abstract getFilters() : TFilters;
    abstract getItemsAsync(search: string, filters: TFilters, skip: number, take: number) : Promise<{items: T[], itemsTotal: number}>;

    /*
    @action fillItems(items: T[], totalCount: number){
        this.items = items;
        this.totalPages = Math.ceil(totalCount / this.pageSize);
    }*/

    @action setPageSize(size: any, page: any) {
        this.pageSize = size;
        this.page = page;
    }

    public getItemById(id: string){
        return this.items.filter((x: any) => x.id == id)[0];
    }

    public wantsPagination(): boolean {
        return true;
    }

    public forceRefresh()
    {
        this.__forcedRefreshToken++;
    }

    ensureLoaded() {
        if(this.__loaderInitialized)
            return;
        this.__loaderInitialized = true;
        reaction(() => ({
            search: this.search,
            filters: this.getFilters(),
            page: this.wantsPagination() ? this.page : 0,
            pageSize: this.wantsPagination() ? this.pageSize : 100000,
            forceRefreshToken: this.__forcedRefreshToken
        }), (arg) => {
            this.__loadingFor = JSON.stringify(arg);
            if(this.__loadedFor == this.__loadingFor)
                return;
            const currentInstanceLoadingFor = this.__loadingFor;
            const loader = async () => {
                const res = await this.getItemsAsync(arg.search, arg.filters, arg.page*arg.pageSize, arg.pageSize);
                if(currentInstanceLoadingFor == this.__loadingFor)
                {
                    this.__loadedFor = currentInstanceLoadingFor;
                    this.items = res.items;
                    this.totalPages = Math.ceil(res.itemsTotal / arg.pageSize)
                }
            }
            this.track(() => loader());
        }, {
            fireImmediately: true
        });
    }
}

class LiveReloadHelper {
    isReloading = false;
    private onReload: () => Promise<void>;
    constructor(onReload: () => Promise<void>) {
        this.onReload = onReload;
    }

    reload(): void {
        if (this.isReloading) return;
        this.onReload()
            .catch((e) => {
                this.isReloading = false;
                console.log(e);
            })
            .then(() => {
                this.isReloading = false;
            });
    }
}