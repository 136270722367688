import React, {FC} from "react";
import {DeathmatchSettingsDto, TeamDeathmatchSettingsDto} from "src/api";
import {useLocalStore, useObserver} from "mobx-react";
import {Button, Input, Table} from "reactstrap";
import {NumericField} from "src/components/NumericInput";
import {useRootStore} from "src/utilities";
import {LoadingIfNull} from "src/components/Loading";

const SettingsEditor : FC<{item: TeamDeathmatchSettingsDto, onChange: (x: TeamDeathmatchSettingsDto) => void}> = (props, onChange) => {
    const store = useLocalStore<
        Omit<(Omit<TeamDeathmatchSettingsDto, 'matchTime'> & {matchTime: number | undefined}), 'fragLimit'> & {fragLimit : number | undefined}>
    (() => ({...props.item}));
    return useObserver(() => (
        <Table>
            <tr>
                <td width={"25%"}>Match time (seconds):</td>
                <td>
                    <NumericField value={store.matchTime} onChange={n => store.matchTime = n}/>
                </td>
            </tr>
            <tr>
                <td width={"25%"}>Frag limit (0 - not limited by frags):</td>
                <td>
                    <NumericField value={store.fragLimit} onChange={n => store.fragLimit = n}/>
                </td>
            </tr>
            <tr>
                <td width={"25%"}>Team names (delimiter = ';'):</td>
                <td>
                    <Input type={"textarea"} value={store.teamNames} onChange={n => store.teamNames = n.target.value}/>
                </td>
            </tr>
            <tr>
                <td colSpan={2} style={{textAlign: "center"}}>
                    <div className={"save-button"}>
                        <Button className={"buttonDarkBlue"} disabled={store.matchTime == undefined} onClick={
                            () => props.onChange({matchTime : store.matchTime!, fragLimit : store.fragLimit!, teamNames : store.teamNames})}>Save</Button>
                    </div>
                </td>
            </tr>
        </Table>
    ));
}

export const ModeTeamDeathmatchSettings: FC = () => {
    const{
        rootStore : {adminGameSettingsStore : store}
    } = useRootStore();
    return useObserver(() => (
        <LoadingIfNull>{store.loading || store.teamDeathmatchSettings == undefined ? null :
            <SettingsEditor item={store.teamDeathmatchSettings!} onChange={async x => {
                await store.rootStore.userRpc.gameSettings.applyTeamDeathmatchSettings(x);
            }}/>}
        </LoadingIfNull>
    ));
};