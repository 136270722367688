import {useObserver} from "mobx-react";
import React, {FC} from "react";
import {useRootStore} from "src/utilities";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import {StandardReactTable, TypedColumn} from "src/components/StandardReactTable";
import {WeaponUsageStatsItemDto} from "src/api";
import {AdminWeaponId} from "src/stores/admin/AdminWeaponStatsStore";
import {InfoBox} from "src/components/InfoBox";
import {AdminModesAndLevelsTab} from "src/stores/admin/AdminGameModesStore";

export const AdminWeaponStatsPage: FC = () => {
    const {
        rootStore: { adminWeaponsStatsStore: store },
    } = useRootStore();
    const columns: TypedColumn<WeaponUsageStatsItemDto>[] = [
        {
            id : "id",
            accessor : x => x.userName,
            Header : "Nickname"
        },
        {
            id : "shoots",
            accessor : x => x.totalShoots,
            Header : "Shoots count"
        },
        {
            id : "hits",
            accessor : x => x.successShoots,
            Header : "Hits count"
        },
        {
            id: "accuracy",
            accessor : x => (x.successShoots / x.totalShoots) * 100.0,
            Header: "Accuracy"
        },
        {
            id : "damage",
            accessor : x => x.totalDamage,
            Header : "Damage instigated"
        },
    ];
    const toggle = (tab: AdminWeaponId) => {
        store.activeTab = tab;
    }
    return useObserver(() => (
        <div>
            <InfoBox.Header>Weapon usage stats</InfoBox.Header>
            <InfoBox.Content>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={store.activeTab == AdminWeaponId.SMG}
                            onClick={() => toggle(AdminWeaponId.SMG)}>SMG</NavLink>
                        <NavLink
                            active={store.activeTab == AdminWeaponId.Shotgun}
                            onClick={() => toggle(AdminWeaponId.Shotgun)}>Shotgun</NavLink>
                        <NavLink
                            active={store.activeTab == AdminWeaponId.Rocketgun}
                            onClick={() => toggle(AdminWeaponId.Rocketgun)}>Rocketgun</NavLink>
                        <NavLink
                            active={store.activeTab == AdminWeaponId.Railgun}
                            onClick={() => toggle(AdminWeaponId.Railgun)}>Railgun</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={store.activeTab}>
                    <TabPane tabId={AdminWeaponId.SMG}>
                        <InfoBox.Content>
                            <StandardReactTable columns={columns} store={store.smgStore}/>
                        </InfoBox.Content>
                    </TabPane>
                    <TabPane tabId={AdminWeaponId.Shotgun}>
                        <InfoBox.Content>
                            <StandardReactTable columns={columns} store={store.shotgunStore}/>
                        </InfoBox.Content>
                    </TabPane>
                    <TabPane tabId={AdminWeaponId.Rocketgun}>
                        <InfoBox.Content>
                            <StandardReactTable columns={columns} store={store.rocketgunStore}/>
                        </InfoBox.Content>
                    </TabPane>
                    <TabPane tabId={AdminWeaponId.Railgun}>
                        <InfoBox.Content>
                            <StandardReactTable columns={columns} store={store.railgunStore}/>
                        </InfoBox.Content>
                    </TabPane>
                </TabContent>
            </InfoBox.Content>
        </div>
    ));
};

export default AdminWeaponStatsPage;