import React from "react";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { Route, RouterState } from "mobx-state-router";
import { UserMainPage, UserProfilePage } from "src/pages/user/UserLazyRoutes";
import { AnonRouteNames } from "src/pages/anon/AnonRoutes";

const UserAuthorizedOnlyHook: RouteTransitionHook = (root) => {
    if (!root.userRpc.isAuthorized) throw new RouterState(AnonRouteNames.userLogin);
};

const EnsureProfileLoadedHook: RouteTransitionHook = async (root) => {
    if (!root.userProfileStore.profile) await root.userProfileStore.loadAll();
};

const UserAllowOnlyFilledProfilesHook: RouteTransitionHook = async (root) => {
    const profile = root.userProfileStore.profile;
    const profileFilled = profile && profile.name;
    if (!profileFilled) throw new RouterState(UserRouteNames.profile);
};

export enum UserRouteNames {
    mainPage = "user-main-page",
    profile = "user-profile-page",
}

export const UserViewMap = {
    [UserRouteNames.mainPage]: <UserMainPage />,
    [UserRouteNames.profile]: <UserProfilePage />,
};

export const UserRoutes: Route[] = convertRoutes([
    {
        pattern: "/user-main",
        name: UserRouteNames.mainPage,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook],
        onEnter: (root) => root.userMainStore.load(),
    },
    {
        pattern: "/profile",
        name: UserRouteNames.profile,
        hooks: [UserAuthorizedOnlyHook, EnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: (root) => root.userProfileStore.loadAll(),
    },
]);
