import { RootStore } from "src/stores/RootStore";
import React, { ChangeEvent, FormEvent, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import {
    registerDecorator,
    ValidationArguments,
    ValidationError,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from "@keroosha/class-validator";
import pageStyles from "src/styles/page.module.css";

@ValidatorConstraint({ name: "MatchProperty" })
export class MatchPropertyConstraint implements ValidatorConstraintInterface {
    validate(value: any, args: ValidationArguments) {
        const [relatedPropertyName] = args.constraints;
        const relatedValue = (args.object as any)[relatedPropertyName];
        return value === relatedValue;
    }
}

export function MatchProperty(property: string, validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [property],
            validator: MatchPropertyConstraint,
        });
    };
}

export function trimEnd(value: string, length: number): string {
    return value.length > length ? value.substring(0, length - 3) + "..." : value.substring(0, length);
}

export const mapChangeEventToValue = <T extends {}>(callback: (x: string) => void) => (
    x: ChangeEvent<HTMLInputElement>
) => callback(x.target.value);

export const preventDefaultEvent = <T extends {}>(callback: (x: FormEvent<HTMLFormElement>) => void) => (
    x: FormEvent<HTMLFormElement>
) => {
    x.preventDefault();
    callback(x);
};

export const useRootStore = () => useContext(MobXProviderContext) as { rootStore: RootStore };

export const reduceValidationErrorsToErrors = <T extends {}>(errors: ValidationError[]) =>
    errors.reduce((acc, x) => ({ ...acc, [x.property]: Object.values(x.constraints ?? {}) }), {} as T);

export const computeInputStyleByErrorArray = (x: unknown[]) =>
    x.length > 0 ? pageStyles.inputError : pageStyles.inputGrey;
